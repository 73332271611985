import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/Layouts/Layout"
import Img, { GatsbyImageSharpFluid } from "gatsby-image"
import ErrorBoundary from "../errors/ErrorHandler"
import { 
    HeroWithMedia as Hero,
    LogoCloudSimpleBranded as LogoCloud,
    FeatureAlternatingWithTestimonial as FeatureWithTestimonial,
    FeatureAlternativeSideImage as Features,
    TestimonialImageOverlapping as Testimonial,
    Image as SiteImage,
    modelFactory 
} from "@domenicosolazzo/site-designsystem"
import  {default as SEO} from "../components/SEO/SEO"

import { DataHomePage } from "../data"

export default function IndexPage() {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    description
                    image
                    url
                    twitterUsername
                    titleTemplate
                    title
                    siteUrl
                    owner
                    facebookAppID
                }
            }
            file(relativePath: { eq: "domenicosolazzo.png" }) {
                childImageSharp {
                # Specify a fluid image and fragment
                # The default maxWidth is 800 pixels
                    fluid(quality: 100, maxWidth: 800, ) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    
    const {
        hero,
        testimonial,
        featureTestimonial,
        features,
        logoCloud
    } = DataHomePage

    const heroWithImage = modelFactory.clone(hero, {
        media: {
            type: "image",
            image: modelFactory.getModel({type: "image", options: {
                component: Img,
                fluid:data.file.childImageSharp.fluid,
                name: "",
                className: "",
                src: "",
                metadata: {
                    type: "image"
                }
            }}),
            link: null
        }
    })

    return (
        <Layout>
            <SEO />
            <ErrorBoundary>
                <Hero {...heroWithImage} />
                <Testimonial {...testimonial}/>
                <Features {...features}/>
                <FeatureWithTestimonial {...featureTestimonial}/>
                <LogoCloud {...logoCloud}/>
            </ErrorBoundary>
        </Layout>
    )
}